/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:50385eca-2e9a-4789-9bf9-89193c8b0259",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_RvvkiPKGo",
    "aws_user_pools_web_client_id": "18qa4ijulum1ui5jaiqc1d4drl",
    "oauth": {}
};


export default awsmobile;
